import React from "react";

import {
  InfoBoxStyled,
  InfoBoxIconWrapperStyled,
  InfoBoxIconStyled,
  InfoBoxHeadingStyled,
  InfoBoxDescStyled,
  InfoBoxNumberWrapperStyled,
  InfoBoxNumberStyled,
} from "./styles";

import { Typo5 } from "../Typography";

interface IProps {
  heading?: string;
  icon?: string;
  iconSize?: string;
  heightSize?: string;
  notCenteredMobile?: string;
  number?: string;
}

const InfoBox: React.FC<IProps> = ({
  heading,
  children,
  icon,
  iconSize,
  heightSize,
  number,
  notCenteredMobile,
  ...otherProps
}) => {
  return (
    <InfoBoxStyled {...otherProps}>
      {number && (
        <InfoBoxNumberWrapperStyled>
          <InfoBoxNumberStyled>{number}</InfoBoxNumberStyled>
        </InfoBoxNumberWrapperStyled>
      )}
      {icon && (
        <InfoBoxIconWrapperStyled
          {...{ iconSize, heightSize, notCenteredMobile }}
        >
          <InfoBoxIconStyled
            data-src={icon.sourceUrl}
            alt={icon.altText}
            className="lazyload blur-up"
            {...otherProps}
            width="80"
            height="80"
          />
        </InfoBoxIconWrapperStyled>
      )}
      {heading && (
        <InfoBoxHeadingStyled>
          <Typo5 as="h3">{heading}</Typo5>
        </InfoBoxHeadingStyled>
      )}
      {children && <InfoBoxDescStyled>{children}</InfoBoxDescStyled>}
    </InfoBoxStyled>
  );
};

export default InfoBox;
