import styled from "styled-components";

import { customMedia, media } from "./../../common/MediaQueries";

const InfoBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${customMedia.maxPhone} {
    padding: 8px;
  }
`;

interface InfoBoxIconWrapperStyledProps { 
  iconSize?: string
  heightSize?: string
  notCenteredMobile?: boolean
}

const InfoBoxIconWrapperStyled = styled.figure<InfoBoxIconWrapperStyledProps>`
  display: inline-block;
  line-height: 1em;
  font-size: ${({ iconSize }) => (iconSize ? iconSize : "58px")};
  margin-bottom: 28px;
  overflow: hidden;
  max-width: 80px;
  height: ${({ heightSize }) => (heightSize ? heightSize : "58px")};

  @media ${customMedia.maxPhone} {
    margin: ${({ notCenteredMobile }) =>
      notCenteredMobile ? "28px 0 0" : "0 auto 28px"};
  }
`;

const InfoBoxIconStyled = styled.img`
  /* height: 1em;
  height: 100%;
  width: 100%;
  max-width: 100%; */
  height: 1em;
  width: auto;

  @media ${customMedia.maxPhone} {
    margin: 0 auto;
  }
`;

const InfoBoxHeadingStyled = styled.div`
  margin-bottom: 6px;

  @media ${customMedia.maxPhone} {
    text-align: center;
  }
`;

const InfoBoxDescStyled = styled.div`
  flex-grow: 1;
  font-weight: ${({ theme }) => theme.fontWeight.light};

  p:not(:last-child) {
    margin-bottom: 10px;
  }

  @media ${customMedia.maxPhone} {
    text-align: center;
  }
`;

const InfoBoxNumberWrapperStyled = styled.div`
  position: relative;
  margin-bottom: 30px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.main};
    opacity: 0.15;

    @media ${media.phone} {
      width: 60px;
      height: 60px;
    }
  }

  @media ${media.phone} {
    margin-bottom: 38px;
  }
`;

const InfoBoxNumberStyled = styled.p`
  font-size: 48px;
  line-height: 1;
  font-family: ${({ theme }) => theme.fontFamilies.proximaNova};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media ${media.phone} {
    font-size: 60px;
  }
`;

export {
  InfoBoxStyled,
  InfoBoxIconWrapperStyled,
  InfoBoxIconStyled,
  InfoBoxHeadingStyled,
  InfoBoxDescStyled,
  InfoBoxNumberWrapperStyled,
  InfoBoxNumberStyled,
};
